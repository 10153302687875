import { ExecSummary } from "@squeeze-io/database";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

interface State {
  competitorExecSummaryData: { [slug: string]: ExecSummary[] } | null;
  currentCompetitorExecSummary: ExecSummary | null;
  initializedCompetitorExecSummaryData: boolean;
}

export interface CompetitorExecSummarySlice extends State {
  setCompetitorExecSummaryData: (
    execSummaryData: { [slug: string]: ExecSummary[] } | null
  ) => void;
  getCurrentCompetitorExecSummary: (
    chart: string,
    classification: string,
    slug: string
  ) => ExecSummary | undefined;
}

export const INITIAL_STATE: State = {
  competitorExecSummaryData: null,
  currentCompetitorExecSummary: null,
  initializedCompetitorExecSummaryData: false,
};

export const createCompetitorExecSummarySlice: StateCreator<
  CompetitorExecSummarySlice,
  [],
  [],
  CompetitorExecSummarySlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setCompetitorExecSummaryData: (data) =>
      set({
        competitorExecSummaryData: data,
        initializedCompetitorExecSummaryData: true,
      }),
    getCurrentCompetitorExecSummary: (
      chart: string,
      classification: string,
      slug: string
    ) => {
      const execSummaries = get().competitorExecSummaryData;
      if (
        !execSummaries ||
        chart === "" ||
        classification === "" ||
        slug === ""
      )
        return undefined;
      return (
        execSummaries[slug] &&
        execSummaries[slug].find(
          (item) =>
            item.chart === chart && item.classification === classification
        )
      );
    },
  };
};
