import {
  FilterOptionsType,
  FilterStateType,
} from "@/components/chart/filter-bar";
import { FunctionalGroups, Locations } from "@/types/review";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

interface State {
  keyAudienceFilter: string;
}

export interface RecruiterSlice extends State {
  setKeyAudienceFilter: (value: string) => void;
}

export const INITIAL_STATE: State = {
  keyAudienceFilter: "product",
};

export const createRecruiterSlice: StateCreator<
  RecruiterSlice,
  [],
  [],
  RecruiterSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setKeyAudienceFilter: (value) => {
      return set({ keyAudienceFilter: value });
    },
  };
};
